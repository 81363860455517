.video-js.video-js .vjs-big-play-button {
  opacity: 0.7;
  width: 80px;
  height: 80px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  border-radius: 90px;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  font-size: 3rem;
  text-align: center;
  line-height: 75px;
}

@media (max-width: 768px) {
  .video-js.video-js .vjs-big-play-button {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .vjs-text-track-display div {
    font-size: 0.85rem !important;
  }
}

.video-js .vjs-progress-control {
  position: absolute;
  width: 100%;
  top: -0.3em;
  height: 3px;
  /* deal with resulting gap between progress control and control bar that
     is the result of the attempt to keep things "clickable" on the controls */
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
}

.video-js .vjs-time-control {
  display: block;
}

.video-js .vjs-remaining-time {
  display: none;
}

.video-js .vjs-progress-holder {
  position: absolute;
  margin: 0 0 5px !important;
  top: 0%;
  width: 100%;
}
.vjs-spacecenter {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: auto;
  min-width: 4em;
  touch-action: none;
}
/* video control always showing*/
:not(.vjs-has-started) .vjs-control-bar {
  display: flex;
}

.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}


/* .vjs-fluid {
    overflow: hidden;
  }
  .vjs-control-bar {
    display: block;
  }
  .vjs-control {
    position: absolute;
  }
  .vjs-progress-control {
    bottom: 15px; left: 0;
    height: 10px;
    width:  120%;
  }
  .vjs-progress-holder  {
    position: absolute;
    left: 0; margin: 0;
    height: 8px; width:  120%;
  }
  .vjs-play-progress,
  .vjs-load-progress {
    height: 8px;
  }
  .vjs-play-progress:before {
    font-size: 12px; top: -2px; 
    text-shadow: 0 0 2px black 
  }
  .vjs-current-time {
    display: block;
    left: 35px;
  }
  .vjs-time-divider {
    position: absolute;
    display: block;
    left: 70px;
  }
  .vjs-remaining-time {
    display: none;   
  }
  .vjs-duration {
    display: block;
    left: 70px;
  }
  .vjs-volume-menu-button {
    position: absolute;
    bottom: 0; right: 55px;
  }
  .vjs-playback-rate {
    position: absolute;
    bottom: 0; right: 28px;
  }
  .vjs-fullscreen-control {
    position: absolute;
    bottom: 0; right: 0;
  } */
