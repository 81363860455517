.addr_ul {
    position: absolute;
    margin: 0;
    padding: 10px;
    max-height: 60%;
    border: 2px solid;
    border-top: none;
    list-style-type: none;
    text-align: left;
    overflow-y: auto;
    width: max-content;
    background-color: white;
    color: black;
    font-size: 1.0rem;
    z-index: 10;
}

.list_item {
    padding: 1rem 1.2rem;
    width: 15rem;
    cursor: pointer;
  
    @include sm {
      width: 20rem;
    }
}

.list-item-darken {
    color: #fff;
    background: #000;
  }

.sub_text{
    margin-left: 0.5rem;
    color: #8c8c8c;
}
.map_container{
  width: 100%;
  height: 100%;
}